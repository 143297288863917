var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { withStylesHof } from 'root/src/client/util/withStylesHof';
const styles = {
    progressBarBackground: {
        width: 280,
        height: 30,
        borderRadius: 12,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        position: 'relative',
    },
    progressBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 10,
        height: '100%',
    },
    progressBarContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        position: 'absolute',
    },
};
const ProgressBar = (_a) => {
    var { classes, progress, children, progressBarColor, progressBarStyle = {}, progressBackgroundColor, progressBackgroundStyle = {}, progressContentStyle = {} } = _a, props = __rest(_a, ["classes", "progress", "children", "progressBarColor", "progressBarStyle", "progressBackgroundColor", "progressBackgroundStyle", "progressContentStyle"]);
    return (<div style={Object.assign({ backgroundColor: progressBackgroundColor }, progressBackgroundStyle)} className={classes.progressBarBackground} {...props}>
		<div className={classes.progressBar} style={Object.assign({ backgroundColor: progressBarColor, width: `${progress}%` }, progressBarStyle)}>
			<div className={classes.progressBarContent} style={progressContentStyle}/>
			{children}
		</div>
	</div>);
};
export default withStylesHof(styles, { name: 'MuiProgressBar' }, ProgressBar);
