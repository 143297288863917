import {
	Fragment,
	memo,
} from 'react'
import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'

const URLCheckRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

const Text = ({ text }) => {
	if (URLCheckRegex.test(text)) {
		return <a className="underline" href={addHttpsProtocol(text)} target="_blank" rel="noreferrer">{text}</a>
	}
	return text
}

export default memo(({ text = '' }) => (
	<>
		{text.split(/\n/).map(col => (
			<Fragment key={col.toString()}>
				{col.split(/ /)
					.map((word, idx) => (
						// eslint-disable-next-line react/no-array-index-key
						<Fragment key={`${word}${idx}`}>
							<Text text={word} />{' '}
						</Fragment>
					))}
				{'\n'}
			</Fragment>
		))}
	</>
))
