import { DarePlatform } from 'root/src/shared/@types/platforms';
import { useDropEvent } from 'root/src/client/logic/dropEvent/hooks/useDropEvent';
import { useUserCreatorData } from 'root/src/client/v2/common/hooks/useUser';
// Return platforms that contain userConnectedPlatforms existing in daresPlatforms,
// If all dares platforms are 'any', then return an array with one platform where the user can accept a maximum of maxDares.
// If daresCount is 0, then return an empty array, indicating that the user can't accept any dares.
export function useConnectedPlatformsByDares(recordId, fallbackDrop) {
    var _a;
    const campaign = (_a = useDropEvent(recordId)) !== null && _a !== void 0 ? _a : fallbackDrop;
    const { connectedPlatforms } = useUserCreatorData();
    if (!campaign) {
        return [];
    }
    const daresPlatforms = getDaresPlatforms(campaign.dares);
    if (daresPlatforms.length === 0) {
        return [];
    }
    return connectedPlatforms.filter(platform => daresPlatforms.includes(platform) || daresPlatforms.includes(DarePlatform.Any));
}
export function getDaresPlatforms(dares) {
    const platforms = new Set();
    dares.forEach(dare => platforms.add(dare.platform));
    if (isOnlyAnyPlatforms(platforms)) {
        return [DarePlatform.Twitch, DarePlatform.TikTok, DarePlatform.YouTube];
    }
    return Array.from(platforms);
}
function isOnlyAnyPlatforms(platforms) {
    return platforms.size === 1 && platforms.has(DarePlatform.Any);
}
