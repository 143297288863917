var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Platform } from 'root/src/shared/@types/platforms';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { twMerge } from 'tailwind-merge';
const platformIcon = {
    [Platform.Twitch]: faTwitch,
    [Platform.YouTube]: faYoutube,
    [Platform.TikTok]: faTiktok,
};
export var PlatformIconColor;
(function (PlatformIconColor) {
    PlatformIconColor["Dark"] = "dark";
    PlatformIconColor["Light"] = "light";
    PlatformIconColor["Colorized"] = "colorized";
})(PlatformIconColor || (PlatformIconColor = {}));
const platformVariant = {
    [PlatformIconColor.Colorized]: {
        [Platform.Twitch]: '*:fill-twitch-purple',
        [Platform.YouTube]: '*:fill-youtube-red',
        [Platform.TikTok]: '*:fill-tiktok-black',
    },
    [PlatformIconColor.Dark]: {
        [Platform.Twitch]: '*:fill-twitch-black',
        [Platform.YouTube]: '*:fill-youtube-black',
        [Platform.TikTok]: '*:fill-tiktok-black',
    },
    [PlatformIconColor.Light]: {
        [Platform.Twitch]: '*:fill-twitch-white',
        [Platform.YouTube]: '*:fill-youtube-white',
        [Platform.TikTok]: '*:fill-tiktok-white',
    },
};
export const PlatformIcon = (_a) => {
    var { platform, color = PlatformIconColor.Light, className } = _a, props = __rest(_a, ["platform", "color", "className"]);
    const icon = platformIcon[platform];
    const classVariant = platformVariant[color][platform];
    return (<FontAwesomeIcon className={twMerge(classVariant, className)} icon={icon} {...props}/>);
};
