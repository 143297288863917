import { useCallback } from 'react'

export const useClipboard = () => {
	/**
	 * Writes text to clipboard
	 * @param {string} text
	 * @return {Promise<void>}
	 */
	const writeText = useCallback(async (text) => {
		try {
			// Permissions API works with Chromium only
			// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
			const result = await navigator.permissions.query({ name: 'clipboard-write' })

			if (result.state === 'denied') {
				return
			}
		} catch (err) {
			// console.error(err)
		}

		await navigator.clipboard.writeText(text)
	}, [])
	/**
	 * Reads text from clipboard
	 * @return {Promise<string | null>}
	 */
	const readText = useCallback(async () => {
		try {
			// Permissions API works with Chromium only
			// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
			const result = await navigator.permissions.query({ name: 'clipboard-read' })

			if (result.state === 'denied') {
				return null
			}
		} catch (err) {
			// console.error(err)
		}

		return navigator.clipboard.readText()
	}, [])

	return {
		writeText,
		readText,
	}
}
