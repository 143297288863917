import sortBy from 'ramda/src/sortBy'
import isNil from 'ramda/src/isNil'
import prop from 'ramda/src/prop'
import { isNilOrEmpty } from 'root/src/shared/util/ramdaPlus'
import reject from 'ramda/src/reject'

export default ({ count, countedProp, returnedProp, breakpoints }) => {
	const breakPointsFiltered = reject(isNilOrEmpty, breakpoints)
	if (breakPointsFiltered.length === 0) return Infinity
	const breakpointsSorted = sortBy(prop(countedProp), breakPointsFiltered)
	const [bHead, ...bTail] = breakpointsSorted
	if (isNil(bHead[countedProp]) || isNil(bHead[returnedProp])) {
		throw { message: 'Bad counted or returned prop' }
	}

	const getBreakpoint = (breakpointsI, currentHighest = bHead) => {
		if (breakpointsI.length === 0) {
			if (count > currentHighest[countedProp]) return Infinity
			return currentHighest[returnedProp]
		}
		const [head, ...tail] = breakpointsI
		if (count > head[countedProp]) {
			return getBreakpoint(tail)
		}
		if (count > currentHighest[countedProp]) {
			return head[returnedProp]
		}
		return currentHighest[returnedProp]
	}

	return getBreakpoint(bTail)
}
