import React, {
	createContext,
	useContext,
	useMemo,
} from 'react'

const recordContext = createContext({
	recordId: undefined,
})

export const useRecordContext = () => {
	if (!recordContext) {
		throw new Error('useRecordContext must be used within a RecordProvider')
	}
	return useContext(recordContext)
}

export const RecordProvider = ({ children, recordId }) => {
	const recordContextValue = useMemo(() => ({ recordId }), [recordId])
	return (
		<recordContext.Provider value={recordContextValue}>
			{children}
		</recordContext.Provider>
	)
}
