import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId';
import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
export function useDropEventSelector(state, recordId) {
    var _a, _b, _c, _d;
    const dropEventId = (_a = currentRouteParamsRecordId(state)) !== null && _a !== void 0 ? _a : recordId;
    return (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state.api) === null || _b === void 0 ? void 0 : _b.records) === null || _c === void 0 ? void 0 : _c.dropEvent) === null || _d === void 0 ? void 0 : _d[dropEventId];
}
export function useDropEvent(recordId) {
    return useAppSelector(state => useDropEventSelector(state, recordId));
}
export function useUserCampaignDataSelector(state, recordId) {
    var _a, _b, _c, _d;
    const dropEventId = (_a = currentRouteParamsRecordId(state)) !== null && _a !== void 0 ? _a : recordId;
    return (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state.api) === null || _b === void 0 ? void 0 : _b.records) === null || _c === void 0 ? void 0 : _c.userCampaignData) === null || _d === void 0 ? void 0 : _d[dropEventId];
}
export function useUserCampaignData(recordId) {
    return useAppSelector(state => useUserCampaignDataSelector(state, recordId));
}
