/* eslint-disable max-len */
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModuleContext } from 'root/src/client/util/withModuleContext'
import reportCtaClick from 'root/src/client/logic/project/thunks/reportCtaClick'
import pinProject from 'root/src/client/logic/project/thunks/pinProject'
import isOneOfAssigneesSelector
	from 'root/src/client/logic/project/selectors/isOneOfAssigneesSelector'
import includes from 'ramda/src/includes'
import { allowPinDareStatuses } from 'root/src/shared/constants/project'
import { getProjectDelivery } from 'root/src/client/logic/project/selectors/projectDeliveriesSelector'
import claimSponsoredProject from 'root/src/client/logic/project/thunks/claimSponsoredProject'
import { getProjectTimeLeft } from 'root/src/client/logic/project/selectors/timeLeftSelector'
import { getProjectGoalAmount } from 'root/src/client/logic/project/selectors/goalAmountSelector'
import { useRecordContext } from 'root/src/client/web/contexts/recordContext'

/**
 * Returns project data from redux store
 * @return {import('root/src/shared/@types/project').Project}
 */
export const useProject = () => {
	const { recordId: projectId } = useRecordContext()

	return useSelector(
		state => state?.api?.records?.project?.[projectId],
		(oldValue, newValue) => oldValue === newValue,
	)
}

export const useProjectExtraData = () => {
	const project = useProject()

	const moduleProps = useModuleContext()

	const { recordId: projectId } = useRecordContext()

	const recordProps = {
		...moduleProps,
		recordId: projectId,
	}

	const isUserOneOfAssignees = useSelector(state => isOneOfAssigneesSelector(state, recordProps))

	const delivery = getProjectDelivery(project?.deliveries)

	return {
		isUserOneOfAssignees,
		displayPin: isUserOneOfAssignees
			&& includes(project.status, allowPinDareStatuses),
		delivery,
		timeLeft: getProjectTimeLeft(project),
		goalAmount: getProjectGoalAmount(project),
		// TODO: it's placeholder as we don't have this data anymore
		liveAssignees: [],
	}
}

export const useProjectActions = () => {
	const { recordId: projectId } = useRecordContext()

	const project = useProject()

	const dispatch = useDispatch()

	// eslint-disable-next-line arrow-body-style
	const onCtaClick = useCallback((event) => {
		event.stopPropagation()
		return dispatch(reportCtaClick(projectId))
	}, [dispatch, projectId, reportCtaClick])

	const onPinProject = useCallback((event) => {
		event.preventDefault()
		return dispatch(pinProject(projectId, project.isPinnedDare))
	}, [dispatch, projectId, project])

	// eslint-disable-next-line arrow-body-style
	const onClaimSponsoredProject = useCallback(() => {
		return dispatch(claimSponsoredProject(project.projectUrl))
	}, [dispatch, projectId, claimSponsoredProject])

	return {
		onCtaClick,
		onPinProject,
		onClaimSponsoredProject,
	}
}

export const useProjectCard = () => {
	const project = useProject()
	const actions = useProjectActions()
	const extraData = useProjectExtraData()

	return {
		project,
		actions,
		extraData,
	}
}

/**
 * @typedef {'projectDelivered' | 'projectAccepted' | 'projectApproved' | 'projectPending' | 'pending' | 'rejected'} ProjectStatus
 */
