import {
	createContext,
	useContext,
} from 'react'

export const dareContext = createContext(null)

export const useDareContext = () => {
	const context = useContext(dareContext)
	if (!context) {
		throw new Error('useDareContext must be used within a DareContextProvider')
	}
	return context
}

export const DareContextProvider = dareContext.Provider
