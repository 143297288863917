import { useBlackCardContext } from 'root/src/client/v2/common/components/BlackCard/hooks/useBlackCardContext'
import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard'
import moment from 'moment-mini'
import classNames from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import twitterLogo from 'root/src/client/public/twitter-logo-banner.png'
import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'
import Link from 'root/src/client/web/base/Link'
import ButtonNew from 'root/src/client/web/base/ButtonNew'
import {
	projectDeliveryPendingKey,
	projectPendingKey,
} from 'root/src/shared/constants/keys'
import {
	ProjectCardLabel,
	statusColorsMap,
} from 'root/src/client/web/list/listItemComponents/project/ProjectCardLabel'
import equals from 'ramda/src/equals'
import PlayIcon from 'root/src/client/public/icons/ic-play-new.svg'
import { useState } from 'react'
import useInterval from 'root/src/client/web/hooks/useInterval'
import ClockIcon from 'root/src/client/public/icons/ic-clock.svg'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import { colorsNew } from 'root/src/client/web/commonStyles'
import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'

const ProjectHeaderImage = withProjectCardStyle(({ classes }) => {
	const { isHovered } = useBlackCardContext()
	const {
		project: { games, projectUrl },
		extraData: { delivery },
	} = useProjectCard()

	const isTwitterDelivery = delivery?.platform === 'twitter'

	return (
		<LazyLoadImage
			src={
				isTwitterDelivery
					? twitterLogo.src
					: delivery && delivery.thumbnail
						? addHttpsProtocol(delivery.thumbnail)
						: addHttpsProtocol(games?.[0]?.boxArtTemplateUrl)
			}
			alt={projectUrl}
			className={classNames({
				[classes.thumbnail]: delivery?.thumbnail,
				[classes.image]: !delivery?.thumbnail,
				[classes.tiktokThumbnail]: delivery?.platform === 'tiktok',
				[classes.scaledUp]: isHovered,
				[classes.scaledDown]: !isHovered,
				[classes.smOpacity]: true,
			})}
			width="280"
			height="320"
		/>
	)
})

const CtaButton = withProjectCardStyle(({ classes }) => {
	const { project: { brandProduct, landingShortUrl }, actions: { onCtaClick } } = useProjectCard()
	return (
		<div className={classes.ctaButtonOverlay}>
			<Link onClick={onCtaClick} href={addHttpsProtocol(landingShortUrl || brandProduct.url)}>
				<ButtonNew type="secondary">
					{brandProduct?.cta?.toUpperCase()}
				</ButtonNew>
			</Link>
		</div>
	)
})

const MarksContainer = withProjectCardStyle(({ classes }) => {
	const {
		project: { isAccepted, isTwitchPanelDare, status, isPledged },
		extraData: { timeLeft, delivery },
	} = useProjectCard()

	const isPending = status === projectPendingKey
	const isExpired = timeLeft <= 0
	return (
		<div className={classes.marksContainer}>
			<div className={classes.status}>
				{isPledged && <ProjectCardLabel status="PLEDGED" color={statusColorsMap.pledged} />}
				{!delivery && isAccepted && !isPending && !isExpired && (
					<ProjectCardLabel status="ACCEPTED" color={statusColorsMap.accepted} />
				)}
				{delivery && equals(status, projectDeliveryPendingKey) && (
					<ProjectCardLabel status="IN REVIEW" color={statusColorsMap.inReview} />
				)}
				{delivery && !equals(status, projectDeliveryPendingKey) && (
					<ProjectCardLabel status="COMPLETED" color={statusColorsMap.delivered} />
				)}
				{!delivery && isExpired && !isTwitchPanelDare && (
					<ProjectCardLabel status="EXPIRED" color={statusColorsMap.expired} />
				)}
				{isPending
					&& <ProjectCardLabel status="PENDING" color={statusColorsMap.pending} />}
			</div>
		</div>
	)
})

const ProjectCardPlayIcon = withProjectCardStyle(({ classes }) => (
	<div className={
		classNames(classes.playIconSpace, classes.playIconContainer)}
	>
		<img src={PlayIcon.src} alt="Play" />
	</div>
))

export const ProjectTimeLeft = withProjectCardStyle(({ classes, timeLeft }) => {
	const [left, setLeft] = useState(Math.max(0, timeLeft))

	useInterval(() => {
		if (left > 0) {
			setLeft(prev => prev - 1)
		}
	}, 1000)

	const timeLeftDuration = moment.duration(left, 'seconds')

	const daysLeft = Math.floor(moment.duration(left, 'seconds').asDays())

	const formattedTimeLeft = daysLeft === 0
		// hh:mm:ss
		? `${String(timeLeftDuration.hours()).padStart(2, '0')}:${String(timeLeftDuration.minutes()).padStart(2, '0')}:${String(timeLeftDuration.seconds()).padStart(2, '0')}`
		: `${daysLeft} DAYS`

	if (typeof left !== 'number') {
		return null
	}

	return (
		<div className={classes.projectTimeLeft}>
			<img src={ClockIcon.src} alt="Clock" />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography
					color={colorsNew.teal}
					variant={typographyVariants.micro}
				>
					TIME LEFT
				</Typography>
				<Typography
					className={classes.projectTimeLeftValue}
					variant={typographyVariants.smallBold}
				>
					{formattedTimeLeft}
				</Typography>
			</div>
		</div>
	)
})

export const ProjectCardHeader = withProjectCardStyle(({ classes }) => {
	const { isHovered } = useBlackCardContext()

	const {
		project: { brandProduct },
		extraData: { timeLeft, delivery },
	} = useProjectCard()

	return (
		<div className={classes.projectCardHeader}>
			{isHovered && brandProduct && brandProduct.amount > 0 && <CtaButton />}
			{!isHovered && delivery && <ProjectCardPlayIcon />}
			<div style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
				<div style={{ display: 'flex', width: '100%', margin: 10, justifyContent: 'space-between' }}>
					<MarksContainer />
					{timeLeft > 0 && <ProjectTimeLeft timeLeft={timeLeft} />}
				</div>
			</div>
			<ProjectHeaderImage />
		</div>
	)
})
