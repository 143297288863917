import {
	withProjectCardStyle,
} from 'root/src/client/web/list/listItemComponents/style'

export const statusNamesEnum = {
	approved: 'approved',
	accepted: 'accepted',
	pending: 'pending',
	delivered: 'delivered',
	rejected: 'rejected',
	inReview: 'inReview',
	expired: 'expired',
	pledged: 'pledged',
}

export const statusLabels = {
	[statusNamesEnum.approved]: 'Approved',
	[statusNamesEnum.accepted]: 'Accepted',
	[statusNamesEnum.pending]: 'Pending',
	[statusNamesEnum.delivered]: 'Delivered',
	[statusNamesEnum.rejected]: 'Rejected',
	[statusNamesEnum.inReview]: 'In Review',
	[statusNamesEnum.expired]: 'Expired',
	[statusNamesEnum.pledged]: 'Pledged',
}

export const statusColorsMap = {
	approved: '#00D33B',
	accepted: '#7A3AED',
	pending: '#00D33B',
	delivered: '#FF7F11',
	rejected: '#d90f0f',
	inReview: '#00D33B',
	expired: '#d90f0f',
	pledged: '#8dcda0',
}

export const ProjectCardLabel = withProjectCardStyle(({ classes, status, color }) => (
	<div
		className={classes.labelContainer}
		style={{ backgroundColor: color }}
	>
		<span className={classes.labelText}>
			{status}
		</span>
	</div>
))
