import getBreakpoint from 'root/src/shared/util/getBreakpoint';
import { Platform } from 'root/src/shared/@types/platforms';
import { maxBy } from 'remeda';
export const getMaxDaresMap = {
    [Platform.Twitch]: ({ campaign, averageCcv, }) => getBreakpoint({
        count: averageCcv,
        countedProp: 'ccv',
        returnedProp: 'maxDares',
        breakpoints: ((campaign === null || campaign === void 0 ? void 0 : campaign.ccvBreakpoints) || []),
    }),
    [Platform.YouTube]: ({ campaign, followerCount, }) => {
        var _a;
        return getBreakpoint({
            count: followerCount,
            countedProp: 'followerCount',
            returnedProp: 'maxDares',
            breakpoints: ((_a = campaign === null || campaign === void 0 ? void 0 : campaign.followersBreakpoints) !== null && _a !== void 0 ? _a : [])
                .filter(breakpoint => breakpoint.platform === Platform.YouTube),
        });
    },
    [Platform.TikTok]: ({ campaign, followerCount, }) => getBreakpoint({
        count: followerCount,
        countedProp: 'followerCount',
        returnedProp: 'maxDares',
        breakpoints: ((campaign === null || campaign === void 0 ? void 0 : campaign.followersBreakpoints) || [])
            .filter(breakpoint => breakpoint.platform === Platform.TikTok),
    }),
};
export function isPlatform(platform) {
    return Object.values(Platform).includes(platform);
}
export function calculateMaxDaresByPlatform({ followerCount, averageCcv, campaign, platform, }) {
    var _a;
    if (!isPlatform(platform)) {
        return 0;
    }
    return (_a = getMaxDaresMap[platform]({ followerCount, averageCcv, campaign })) !== null && _a !== void 0 ? _a : 0;
}
export function calculateMaxDaresForBestPlatform({ followerCount, averageCcv, campaign, connectedPlatforms }) {
    var _a;
    if (connectedPlatforms.length === 0) {
        return null;
    }
    const maxDaresByProviders = connectedPlatforms.map(provider => ({
        maxDares: getMaxDaresMap[provider]({ followerCount, averageCcv, campaign }),
        platform: provider,
    }));
    if (!maxDaresByProviders.length) {
        return null;
    }
    return (_a = maxBy(maxDaresByProviders, x => x.maxDares)) !== null && _a !== void 0 ? _a : null;
}
